.back-ground6 {
    height: 40vh;
    background:
    url('../Images/servicemain.png');   background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
}
.yu {
    padding-top: 30px;
}

.h3modify {
font-size: clamp(29px, 4vw, 44px);
font-weight: 700;

}

.home-section6{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.h4modify {
    font-size: clamp(21px, 3vw, 34px);
    font-weight: 700;
    
    }
.pmodify {
    font-size: clamp(13px, 3vw, 19px);
    padding-top: 10px;
        
        }  
        
.divwd {
    display: flex;
    gap:20px;
}
.imgdiv1 {
    width: fit-content;
    height: fit-content;
    object-fit: cover;
    max-width: 30%;
}
.paradiv {
    font-size: small;
}
@media only screen and (max-width: 767px) {
    .bi {
    display: flex !important;
    gap: 60px;
   
    }
  }