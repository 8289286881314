.back-ground4 {
    height: 60vh;
    background:
    url('../Images/about1.jpg');   background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    
}

.h2edit4 {
font-size: clamp(29px, 4vw, 44px);
font-weight: 700;

}
.pedit4 {
font-size: clamp(20px, 5vw, 16px);
font-weight: 400;
margin-bottom: 0px !important;
;

}
.home-section4{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.logoes {
    display: flex;
    gap: 25px;
}