 .race img {
   vertical-align: middle;
   max-width: 60%;
   aspect-ratio: 3 / 2;
   object-fit: contain;
 }

 .navbar-custom {
   border-bottom: 1px solid #d8d8d8;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {}

 a,
 a:hover,
 a:focus,
 a:active {
   text-decoration: none;
   outline: none;
 }

 a,
 a:active,
 a:focus {
   color: #333;
   text-decoration: none;
   transition-timing-function: ease-in-out;
   -ms-transition-timing-function: ease-in-out;
   -moz-transition-timing-function: ease-in-out;
   -webkit-transition-timing-function: ease-in-out;
   -o-transition-timing-function: ease-in-out;
   transition-duration: .2s;
   -ms-transition-duration: .2s;
   -moz-transition-duration: .2s;
   -webkit-transition-duration: .2s;
   -o-transition-duration: .2s;
 }

 ul {
   margin: 0;
   padding: 0;
   list-style: none;
 }

 img {
   max-width: 100%;
   height: auto;
 }

 section {
   /* min-height: 100vh;*/
 }

 .btn {
   padding: 14px 25px;
   text-transform: uppercase;
   -webkit-transition: all 0.8s;
   -moz-transition: all 0.8s;
   -o-transition: all 0.8s;
   transition: all 0.8s;
 }

 .red-btn {
   background: white;
   border: 2px solid black;
   color: black;
 }

 .red-btn:hover {
   background: black;
   color: white;
   border-color: #ffffff;
 }

 /*---------footer---------*/
 footer {
   background-color: black;
   /* background-image: -webkit-linear-gradient( top, #222023, #1e2c47 );
background-image: -moz-linear-gradient( top, #222023, #1e2c47 );
background-image: -o-linear-gradient( top, #222023, #1e2c47 );
background-image: linear-gradient( to bottom, #222023, #1e2c47 ); */
   color: #fff;
   padding: 90px 0;
   font-size: 17px;
 }

 .table {
   --bs-table-bg: black !important;

 }

 .table>:not(caption)>*>* {
   color: white !important;
 }

 footer h3 {
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 1px;
 }

 footer h4 {
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 1px;
   display: inline-block;
   margin-bottom: 2px;
 }

 .about-footer li i {
   position: absolute;
   left: 0;
 }

 .about-footer li {
   padding-left: 40px;
   position: relative;
   margin-bottom: 40px;
 }

 .about-footer ul {
   margin-top: 40px;
 }

 footer a {
   color: #fff;
 }

 footer a:hover {
   color: #ed1b24;
 }

 .footer-title {
   border-bottom: 2px solid #a61f2d;
   padding-bottom: 25px;
   margin-bottom: 35px;
 }

 ul.footer-social {
   float: right;
 }

 ul.footer-social li {
   display: inline;
   margin-right: 16px;
 }

 ul.footer-social i {
   width: 30px;
   height: 30px;
   background: #fff;
   color: #222025;
   text-align: center;
   line-height: 30px;
   border-radius: 30px;
   font-size: 16px;
   -webkit-transition: all 0.5s;
   -moz-transition: all 0.5s;
   -o-transition: all 0.5s;
   transition: all 0.5s;
   font-weight: 700;
 }

 ul.footer-social li:last-child {
   margin-right: 0px;
 }

 ul.footer-social i:hover {
   background: #ed1b24;
   color: #fff;
 }

 .page-more-info li {
   margin-bottom: 31px;
 }

 footer .table td:first-child {
   font-weight: 600;
   padding-left: 33px;
 }

 footer .table td:last-child {
   text-align: right;
 }

 footer .table td {
   padding: 0px;
   border: 0;
 }

 footer .table tr {}

 footer .table td i {
   position: absolute;
   left: 0px;
   font-size: 21px;
   top: 6px;
 }

 footer .table td {
   position: relative;
   padding: 4px 0;
 }

 .footer-logo td {
   padding-right: 4px !important;
 }

 .footer-logo td:last-child {
   padding-right: 0px !important;
 }

 footer hr {
   border-color: #9294a0;
 }

 .footer-policy p {
   font-size: 16px;
   color: #000000;
 }

 .footer-policy {
   margin-top: 10px;
   font-family: "Circular Air", Sans-serif;

 }

 .open-hours hr {
   margin: 30px 0;
 }

 .landing_page_iamge {
   max-width: 95%;
 }
.ctns{
margin: 0px 26px 0px 36px !important;
}
 .button_for_start {
   background-color: black;
   padding-left: 46px;
    padding-right: 46px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: circular air, Sans-serif;
    font-size: 1.15em;
    font-weight: 400;
    text-shadow: 0 0 10px rgba(0, 0, 0, .3);
    color: #fff;
 }

 .button_for_start1 {
   color: white;
   background-color: black;
   padding: 11px;
   font-size: x-large;
   padding: 0px 11px 11px 11px;
 }

 .google img {
   max-width: 169px;

 }
.google h2{
  font-family: adieu light, Sans-serif;
    font-size: 21px;
    font-weight: 100;
    letter-spacing: -1px;
    display: flex;
    justify-content: center;
}
 .containers {
   display: grid;
   /* grid-template-columns: repeat(5, 1fr); */
   /* gap: 20px; */
   grid-column-gap: auto !important;
   flex-wrap: wrap;
   justify-content: space-around;
   /* grid-gap: 7rem; */
   padding: 10px;
 }
.containerss{
  justify-content:space-between !important;
}

 .advertisment_section {
   margin-top: 18x;
 }
 .nav-link.active {
  border-bottom: 2px solid orangered; /* Change the color and width as needed */
}
@media(min-width:991px){
  #offcanvasNavbar{
      display: none;
            
  }
}
 .containet_holder {
   padding: 0px 46px 0px 46px;
 }

 .add_new {
   padding: 40px 0px 50px 0px;

 }

 .main_class_conts {
   display: flex;
   gap: 20px;
   padding: 24px 0px 24px 0px
 }

 .mccts {
   padding: 10px 0px 10px 0px !important;

 }

 .img_section {
   width: 143px;
 }

 .add_heading h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1.5em;
   font-weight: 700;
   letter-spacing: -1px;
 }

 .leaf h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1.25em;
   font-weight: 400;
 }

 .abc {
   width: 16% !important;
 }

 .add_website_conversions h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 16px;
   font-weight: 400;
 }

 .add_website_conversions_Strategies h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1.5em;
   font-weight: 700;
   line-height: 58px;
   letter-spacing: 0.5px;
 }



 .Everything p {
   text-align: left;
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1em;
   font-weight: 400;
   font-style: italic;

 }

 .add_costume_Increase {
   top: 30%
 }

 .add_frustrated h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1.25em;
   font-weight: 500;
 }

 .border-primary {
   --bs-border-opacity: 1;
   border-color: rgb(13 110 253 / 17%) !important;
 }

 .added_for_padding {
   padding: 50px;
 }

 .expect h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 2.5em;
   font-weight: 700;
   line-height: 50px;
 }

 .Outline h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1.25em;
   font-weight: 600;
   line-height: 1.3em;
 }

 .main_containt {
   padding: 30px 0px 0px 80px;
 }

 .beat h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1.5em;
   font-weight: 700;
   line-height: 44px;
 }

 .Lou h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 18px;
   font-weight: 700;
   line-height: 30px;

 }

 .Radio h2 {
   color: #000000;
   font-family: "Circular Air", Sans-serif;
   font-size: 1em;
   font-weight: 300;
   line-height: 0em;
 }
.form-control{
  border: 1px solid black !important;
  border-radius: 0%;
}
.googles{
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.googles img {
  max-width: 170px;
}
.elementor-toggle-title{
  font-family: circular air, Sans-serif;
    font-size: 1.15em;
    font-weight: 700;
}

.add_gapped{
  gap: 70px;
}
.landing_page_iamge button{
  width: 100%;
  background-color: black;
  color: white;
}
.elementor-hea{
  color: #000;
  font-family: circular air, Sans-serif;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: -1px;
}
 .add_price_content {
   margin-bottom: 80px
 }

 .added {
   margin-bottom: 20px
 }
.d3dd92e{
  margin-top: 60px;
}
 .Social_media_heading h2 {
   color: #000;
   font-family: circular air, Sans-serif;
   font-size: 45px;
   font-weight: 900;
   letter-spacing: -1px;
 }
 .landing_pages{
  padding: 43px;
 }
 .mcctes{
  gap: 0px !important;
 }
 .img_sections{
  padding: 0px 10px 0px 0px  !important;
  width: 39px !important;
 }
.add_cstm{
  justify-content: center;
  display: flex;
  border: 2px solid black;
}
.add_cstm img{
  max-width: 80%;
}
 .main_containt_ovrerwrite h2 {
   color: #000;
   font-family: circular air, Sans-serif;
   font-size: 2em;
   font-weight: 700;
   text-transform: capitalize;
   line-height: 1.3em;
   letter-spacing: -1px;
 }

 .social_media {
   margin-bottom: 80px;
   margin-top: 60px;
 }

 .added_social {
   padding: 40px 0px 40px 0px;
 }

 .img_section {
   display: flex;
   align-items: center;
   width: 70px ;
 }
 .section_contss{
  padding: 0px 0px 0px 10px !important;
 }
 .elementor-size-default{
  color: #000;
    font-family: circular air, Sans-serif;
    font-weight: 700;
 }
 .elementor-size-default{
  color: #000;
  font-family: circular air, Sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  text-decoration: underline;
 }
/* 
 .section_cont h2 {
   color: #000;
   font-family: circular air, Sans-serif;
   font-size: 2em;
   font-weight: 700;
   text-transform: capitalize;
   line-height: 1.3em;
   letter-spacing: -1px;
 }

 */
 .section_conts p {
  /* color: #000;
  font-family: circular air, Sans-serif;
  font-size: 1.15em;
  font-weight: 400; */
  font-style: italic;
}
 .elementor-price-table__price {
   font-family: circular air, Sans-serif;
   font-size: 3em;
   font-weight: 900;
   padding: 40px 0px 40px 0px;

 }

 .elementor-price-table__currency {
   font-size: calc(42em/100);
   align-self: flex-start;
   color: #000;
   margin-right: 3px;
 }

 .elementor-price-table__integer-part {
   font-family: circular air, Sans-serif;
   font-size: 1em;
   font-weight: 900;
 }

 .elementor-price-table__period {
   line-height: normal;
   letter-spacing: normal;
   text-transform: none;
   font-weight: 400;
   font-size: medium;
   font-style: normal;
 }

 .elementor-price-table__features-list {
   list-style-type: none;
   margin: 0;
   padding: 0;
   line-height: 1;
   color: var(--e-price-table-features-list-color);
 }

 .elementor-price-table__features-list li {
   font-size: 14px;
   line-height: 1;
   margin: 0;
   padding: 0;
 }

 .elementor-price-table__features-list i {
   margin-right: 10px;
   font-size: 1.3em;
 }

 .elementor-price-table .elementor-price-table__features-list li:not(:first-child):before {
   content: "";
   display: block;
   border: 0 solid hsla(0, 0%, 47.8%, .3);
   margin: 10px 12.5%;
 }

 .elementor-price-table__header {
   background: var(--e-price-table-header-background-color, black);
   padding: 20px 0;
   text-align: center;
 }

 .elementor-price-table__header h2 {
   color: white;
 }

 .elementor-price-table__header span {
   color: white;
 }

 .add_element_new {
   margin-top: 100px;
 }

 .elementor-heading-title h2 {
   color: #000;
   font-family: circular air, Sans-serif;
   font-size: 1.5em;
   line-height: 1.3em;
 }

 .add_element_news {
   background-color: #f5f3f1;
   margin-top: 100px;
 }

 /* .elementor-heading-title h2 {
   width: 438px;
 } */
.added_marg{
  margin-top:80px
}
 .elementor-toggle {
   text-align: start
 }

 .elementor-toggle .elementor-tab-title {
   font-weight: 700;
   line-height: 1;
   margin: 0;
   padding: 15px;
   border-bottom: 1px solid #d5d8dc;
   cursor: pointer;
   outline: none
 }

 .elementor-toggle .elementor-tab-title .elementor-toggle-icon {
   display: inline-block;
   width: 1em
 }

 .elementor-toggle .elementor-tab-title .elementor-toggle-icon svg {
   margin-inline-start: -5px;
   width: 1em;
   height: 1em
 }

 .elementor-toggle .elementor-tab-title .elementor-toggle-icon.elementor-toggle-icon-right {
   float: right;
   text-align: right
 }

 .elementor-toggle .elementor-tab-title .elementor-toggle-icon.elementor-toggle-icon-left {
   float: left;
   text-align: left
 }

 .elementor-toggle .elementor-tab-title .elementor-toggle-icon .elementor-toggle-icon-closed {
   display: block
 }

 .elementor-toggle .elementor-tab-title .elementor-toggle-icon .elementor-toggle-icon-opened {
   display: none
 }

 .elementor-toggle .elementor-tab-title.elementor-active {
   border-bottom: none
 }

 .elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-closed {
   display: none
 }

 .elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-opened {
   display: block
 }

 .elementor-toggle .elementor-tab-content {
   padding: 15px;
   border-bottom: 1px solid #d5d8dc;
   display: none
 }

 @media (max-width:767px) {
  .elementor-heading-title h2{
text-align: center;
font-size: 18px;
padding: 10px;
  }
  .added_for_padding{
padding: 10px;
  }
  .social_media_paragraph{
    text-align: center;
  }
   .elementor-toggle .elementor-tab-title {
     padding: 12px
   }

   .elementor-toggle .elementor-tab-content {
     padding: 12px 10px
   }
   .button_for_start{
    width: auto  !important;
    padding: 10px 30px;
 
      }
      .button_Contact{
        display: flex;
        justify-content: center;
      }
      .btns{
        display: flex;
        align-items:center;
        justify-content:center;
      }
      .elementor-price-table__header{
margin-top:30px
      }
     
    
 }

 .e-con-inner>.elementor-widget-toggle,
 .e-con>.elementor-widget-toggle {
   width: var(--container-widget-width);
   --flex-grow: var(--container-widget-flex-grow)
 }
 ::placeholder {
  color: white;
}
/* src/styles.css */

.consultation-form{
  background-color: #FAFAFB;
  color: #71717A;
  padding-top: 100px;
}
.consultation-form input,
.consultation-form textarea {
  background-color: white !important; 
  color: #71717A  !important;
  border: 1px solid #71717A !important;
  border-radius: 0 !important;
  margin-bottom: 15px !important;
  padding: 15px;
}
.smh{
  color: #000;
  font-family: circular air, Sans-serif;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: -1px;
}
.divider-container {
  display: flex;
  align-items: baseline;
  margin-top: 10px; /* Adjust as needed */
}

.divider-text {
  margin-right: 10px; /* Space between text and line */
}

.divider-line {
  flex-grow: 0;
  height: 1px;
  background-color: #000; /* Adjust color as needed */
}
.consultation-form input::placeholder,
.consultation-form textarea::placeholder {
  color: #777 !important; 
}

.consultation-form button {
  background-color: #000 !important; 
  color: #fff !important;
  border: none !important;
  padding: 15px 30px !important;
  cursor: pointer !important;
}

.consultation-form button:hover {
  background-color: #f0f0f0 !important; 
}
.custom-hr {
  border: none;
  height: 2px;
  background-color:rgb(232, 211, 92);
  margin: 0; 
  padding: 0;
  width: 100%; 
}
/* .collapse{
  margin-right: 100px !important;
} */
@media(max-width:991px){
  .mes{
  justify-content: left !important;
  margin-right: 0px;

  }
}
.mes{
 display: flex;
 justify-content: end;
 align-items: end;

}
.nav-link .active {
  border-bottom: 2px solid orange;
}
.attachment-large {
  max-width: 25% !important;
}
.add_element_news_background{
  background-color: #f5f3f1;
}
.google12{
  width: 16%;
  color: white;
  background-color: black;
}
.paragraph{
  color: var(--e-global-color-624dec5);
    font-family: circular air, Sans-serif;
    font-size: 1.2em;
    font-weight: 400;
}
.heading_text_content{
  color: #000;
    font-family: circular air, Sans-serif;
    font-size: 2.5em;
    font-weight: 700;
    letter-spacing: -1px;
}
.line{
margin: 0px;
}
.add_new_padding{
  padding-bottom: 100px;
}
.elemntor_add{
  margin-top:3rem;
}
.google123{
  display:none !important;
}
.googles{
  width: 290px;
}
.google{
  height: 50px ;
  /* padding: 20px; */
}
.text{
  color: #000;
  font-family: circular air, Sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  letter-spacing: -1px;
}
.heading_text{
  padding: 3rem;
}

@media(max-width:992px){
  .text
  {
   text-align: center;
   font-size: 2em !important;
  }
  .heading_text{
    padding: 10px !important;
  }
  .heading_text_content{
text-align: center;
font-size: 1.6em !important;
  }

  .expert h2{
    text-align: center;
  }
  .elementor-divider{
    text-align: center;
  }
  .advertisment_banner{
    text-align: center;
  }
  .expect{
    text-align: center;
  }
  .expect span{
    text-align: center;
  }
  .elementor-price-table__header{
    margin-top:30px
          }
  .paragraph{
    text-align: center;
    font-size: 1em;
  }
  .ctns {
    margin: 0px 16px 0px 16px !important;
}
  .button_for_start{
width: 100%;
  }
  .add_new_padding{
    display: flex;
    justify-content: center;
  }
  .main_containt{
    padding: 0px;
  }
  .add_costume_Increase {
    top: 0%;
    margin-bottom: 40px;
  }
  .add_new_class_reverse{
    flex-direction: column-reverse;
  }
  .Outline h2{
    text-align: center;
  }
  .Outline p{
    text-align: center;
  }
.process span{
  text-align: center;
}
.elementor-heading-titles {
  font-size: 1.50em;

}
.social_media{
  margin-bottom:0px;
  margin-top:0px;
}
.elemntor_add{
  margin-bottom:0px;
  margin-top:0px !important;
}
.main_containt_ovrerwrite h2{
  font-size: 1.5em;
}
.smh{
  font-weight: 700;
}
.elementor-hea{
  font-weight: 600;
  font-size: 1.50em;
}
.elementor-size-default{
  font-size: 1.6em;
        line-height: 1.2em;
        text-decoration: none !important;
        text-align: center;
}
.brands-container {
  margin:0px;
}
}

  /* .advertisment_section {
    padding: 20px 0;
  } */

  .line {
    border: 1px solid #000;
  }

  /* .brands-container {
    margin: 20px 0px 20px 0px;
  } */
  .brand-logo_heading{
background-color: #000;
color: white;
  }
  .add_for_changes{
    display: none;
  }
  .brand-logo img{
   padding: 20px 0px 20px 0px ;
  }
  .brand-logo {
    flex: 1 1 16%; /* Adjust this to control the number of logos per row */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px 0px ;
  }
  /* .brand-logo h2{
    width: 103px;
  } */
  .brand-logo img {
    width: 100px; /* Adjust this size as needed */
    height: auto;
  }

  @media (max-width: 768px) {
    .brand-logo {
      flex: 1 1 40%; /* Adjust for fewer logos per row on small screens */
    }
    .google img {
      max-width: 122px;
      object-fit: contain;
    }
    .brand-logo_heading
    {
      flex: 1 1 62% !important ;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px 20px 0px ;
    }
    .add_for_changes_new{
display: none;
    }
    .add_for_changes{
      display:flex;
    }
    .brand-logo img {
      max-width: 80px; /* Smaller logo size for mobile */
    }
  }

  @media (max-width: 576px) {
    .brand-logo {
      flex: 1 1 49%; 
      margin-bottom: 15px;
    }

    .brand-logo img {
      width: 100px;
    }
    
    .brand-logo {
      width: 100px;
    }
    .containet_holder {
      padding: 0px;
  }
  }
.f6{
font-size: 1em;
}
/* @media (max-width: px) {
  .google img {
    max-width: 122px;
  }
} */

.colr {
  color: #000000;
}
.prent {
  background-color: #FAFAFB;
}











































/* Add this to your CSS file */
.offcanvas-start {
  width: 250px; /* Adjust the width as needed */
}

.offcanvas-title {
  margin-left: 10px;
}

.navbar-toggler {
  border: none;
}

.navbar-custom {
  background-color: #f8f9fa; /* Customize as needed */
}

/* Additional styles for the nav links */
.nav-link.change {
  color: #343a40; /* Customize as needed */
}

.nav-link.change.active {
  font-weight: bold;
  color: #000; /* Customize active link color */
}
.offcanvas {
  width: 200px !important;
}
@media (max-width:991px) {
  .btnn {
    display: none;
  }

}
@media (max-width:991px) {
  .btn1 {
    display: flex !important;
  }

}
.btn1 {
  display: none;
  padding: 10px;
}
.hww {
  gap:20px;
}
.btn2 {
  color: white;
  padding: 10px;
}


