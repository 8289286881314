.back-ground1 {
    height: 60vh;
    background:
    url('../Images/about1.jpg');   background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.h2edit {
font-size: clamp(29px, 4vw, 44px);
font-weight: 700;

}
.pedit {
font-size: clamp(18px, 5vw, 16px);
font-weight: 400;
margin-bottom: 0px !important;

}

.home-section1{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.seconddiv {
    font-size: medium;
    line-height: 28px;
}
.para {
    font-size: small;
}
@media(max-width:767px){
    .para{
        margin: 0px !important;
        font-size: small;
    }
}

.div1 {
    display: flex;
    gap: 20px;
}
.imgdiv {
    width: fit-content;
    height: fit-content;
    object-fit: cover;
}
.paradiv {
    font-size: small;
}
@media(max-width:767px){
    .fs{
        font-size: small;
    }
}



