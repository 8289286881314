.back-ground3 {
    height: 60vh;
    background:
    url('../Images/blogmain.png');   background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    
}

.h2edit3 {
font-size: clamp(29px, 4vw, 44px);
font-weight: 700;


}
.pedit3 {
font-size: clamp(20px, 5vw, 16px);
font-weight: 400;
margin-bottom: 0px !important;
;

}
.home-section3{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}