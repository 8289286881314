.back-ground2 {
    height: 60vh;
    background:
    url('../Images/Frame1.png');   background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    
}

.h2edit1 {
font-size: clamp(29px, 4vw, 44px);
font-weight: 700;

}
.pedit1 {
font-size: clamp(20px, 5vw, 16px);
font-weight: 400;
margin-bottom: 0px !important;

}
.home-section2{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.paragraph_texts{
    font-size: small;
    margin-bottom: 1.8rem;
}
.headingdiv{
    margin-top: 25px;
}
.heading_texts{
    margin-bottom: 1.8rem;
    font-weight: 400;
}
.know_me{
    color: rgba(30, 30, 184, 0.854);
    font-size: smaller;
    font-weight: 600;

}

body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
  }
  
  .textcolor {
    color: #71717a;
  }
  
  @media only screen and (max-width: 768px) {
    .reverse {
      flex-direction: column-reverse;
    }
  }
  
  h1 {
    font-size: calc(1rem + 1.3vw);
  }
  
  .datasize {
    line-height: revert;
    color: #71717a;
  }
  
  .btn-primary {
    color: #007bff;
    background-color: transparent;
    border: none;
    font-weight: bold;
    padding: 10px;
  }
  
  
  
  .images {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  
